// App Startpoint 
// Does all the Routing
// Checks if User is logged in 

import React from 'react';
import {
	Router,
	Switch,
	Route,
	Redirect
} from 'imports/react-router';

/*import {
	Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';*/

import 'TikTag.scss';

// tools
import { Request } from 'tools/Request';

// Pages
import Login from 'pages/Login';
import Register from 'pages/Register';
import Home from 'pages/Home';
//import Dashboard from 'pages/Dashboard';
import IDE from 'pages/IDE';
//import Marketplace from 'pages/Marketplace';
import Account from 'pages/Account';
import Notifications from 'pages/Notifications';
//import Collections from 'pages/Collections';
import Navigation from 'components/Navigation';
//import Taglist from 'components/Taglist';

// Components
import { FirebaseController } from 'components/Firebase';

class TikTag extends React.Component
{
	constructor()
	{
		super();

		this.state = {
			account: false,
			loading: true,
		}
		this.onAuthChanged = this.onAuthChanged.bind(this);
		this.firebase = FirebaseController;

//		Home.setFB(this.firebase);
	}

	async componentDidMount()
	{
		this.firebase.init({
			onAuthChanged: this.onAuthChanged,
			apiKey: "AIzaSyCmsPdnxSBUe-wCftrq6k6XAwUHY4JLG88",
			authDomain: "tiktag-7971f.firebaseapp.com"
		});

//		console.dir (this.firebase);
//		console.dir (process.env)
	}

	async onAuthChanged(account)
	{
/*		try
		{
			const re = await Request('post', '', {test: "Eike"});
			console.log(re);
		} 
		catch (e)
		{
			console.log(e);
		}*/

		this.setState({account,loading: false});
	}

	render()
	{
		if (this.state.account)
		{
			return(
				<Router>
					<Navigation account={this.state.account}/>
					<Switch>
						<Route path='/ide/:cmd?/:parameters?'>
							<IDE account={this.state.account} firebase={this.firebase}/>
						</Route>
						<Route path='/home' component={Home} />
						<Route path='/account'>
							<Account account={this.state.account} />
						</Route>
						<Route path='/notifications' component={Notifications} />
						<Redirect to='/home' />
					</Switch>
				</Router>
			)
		} else if (this.state.loading)
		{
			return "loading";
		} else
		{
			return(
				<Router>
					<Navigation account={this.state.account}/>
					<Switch>
						<Route path="/home" render={(props) => <Home {...props} account={this.state.account}/>}/>
						<Route path='/login' component={Login} />
						<Route path='/register' component={Register} />
						<Redirect to='/home' />
					</Switch>
				</Router>
			)
		}
	} 
}

// 						<Route path='/home' element={<Home firebase={this.firebase}/>} component={Home}/>


export default TikTag;

