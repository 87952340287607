import React, { Component } from 'react'
import { withRouter } from 'imports/react-router';
//import { withRouter, useParams } from 'react-router-dom';

// Components
import CIDE from 'ide.js';

import './IDE.scss';

class IDE extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			data: {},
			tagName: '',
			showModal: false,
		};
		
//		console.log ("REACT IDE PROPS: ");
//		console.dir (this.props);
		this._ideDiv = React.createRef();
//		console.log ("before new?");
		this._browser = new CIDE();
//		console.log ("after new?");

		this.fileBrowserParentRef = React.createRef();
		this.textEditorParentRef = React.createRef();
		this.resizerRef = React.createRef();
		this.mainRef = React.createRef();
	}

	componentDidMount()
	{
        let cmd = this.props.match.params.cmd;
        let parameters = this.props.match.params.parameters; 
		let obj = this._ideDiv.current;
		var curRect = obj.getBoundingClientRect();
//		console.dir(this.props);
		this._browser.mount(this._ideDiv.current, this.props.account, this.props.firebase, cmd, parameters);
	}

	/*resizeBody(event)
	{
		const originalFBX = this.fileBrowserParentRef.current.getBoundingClientRect().left;
		const originalTEX = this.textEditorParentRef.current.getBoundingClientRect().left;
		const originalMouseX = event.pageX;
		const originalFBWidth = parseFloat(getComputedStyle(this.fileBrowserParentRef.current, null).getPropertyValue('width').replace('px', ''));
		const originalTEWidth = parseFloat(getComputedStyle(this.textEditorParentRef.current, null).getPropertyValue('width').replace('px', ''));
		this.mainRef.current.onmousemove = e => this.changeLayout(e, originalFBX, originalTEX, originalMouseX, originalFBWidth, originalTEWidth);
	}

	changeLayout(event, originalFBX, originalTEX, originalMouseX, originalFBWidth, originalTEWidth)
	{
		const fbWidth = originalFBWidth + (event.pageX - originalMouseX);
		const teWidth = originalTEWidth - (event.pageX - originalMouseX);
		this.fileBrowserParentRef.current.style.width = fbWidth + 'px';
		this.textEditorParentRef.current.style.width = teWidth + 'px';
		this.setState({});
	}

	stopResize()
	{
		this.mainRef.current.onmousemove = null;
	}

	renderModal()
	{
		return(
			<div className="IDE_Modal">
				<div className="IDE_Modal_Wrap">
					<div className="IDE_Modal_Head">
						Create a new Project
					</div>
					<div className="IDE_Modal_Body">
						<div className="IDE_Modal_Input_Wrapper">
							<span className="IDE_Modal_Input_Label">Name:</span>
							<input className="IDE_Modal_Input" value={this.state.tagName} onChange={e => this.setState({tagName: e.target.value})} />
						</div>
							Please choose if you would like to create a Tag or a Library?
					</div>
					<div className="IDE_Modal_Action">
						<button className="IDE_Modal_Button" onClick={e => this.initIDE('library')}>Create Library</button>
						<button className="IDE_Modal_Button" onClick={e => this.initIDE('tag')}>Create Tag</button>
					</div>
				</div>
			</div>
		)
	}

	renderFileBrowser()
	{
		if (this.state.currentWorkspace === "")
		{
			return(
				<div>loading...</div>
			)
		} else
		{
			return(
				<FileBrowser />
			)
		}
	}*/

	render()
	{
		/*return(
			<div ref={this.mainRef} onMouseUp={e => this.stopResize()} className="IDE_Main">
				<Controls/>
				<div className="IDE_Body">
					<div ref={this.fileBrowserParentRef} className="IDE_File_Browser">
						{ this.renderFileBrowser() }
					</div>
					<div ref={this.resizerRef} className="IDE_Resizer" onMouseDown={e => this.resizeBody(e)}></div>
					<div ref={this.textEditorParentRef} className="IDE_Text_Editor">
						<TextEditor />
					</div>
				</div>
			</div>
		)*/
		return(
			<div 
				ref={this._ideDiv} 
				className="IDE_Main_React" 
			>
			</div>
		)
	}
}

export default withRouter(IDE);