// Marketplace Page
// Site for Users to add and install ne Tags to your Website

import React, { Component } from 'react'
import {
	withRouter,
	Switch,
	Route,
	Router,
	useRouteMatch
} from 'imports/react-router';

// Components
import { FirebaseController } from 'components/Firebase';


class Account extends Component
{
	
	loginWithGoogle()
	{
		try
		{
			FirebaseController.loginWithGoogle();
		} catch (e)
		{
			console.log(e);
		}	
	}

	loginWithMicrosoft()
	{
		try
		{
			FirebaseController.loginWithMicrosoft();
		} catch (e)
		{
			console.log(e);
		}	
	}

	render()
	{
		return(
			<div>
				<button className="max-w-12 leading-4  p-2 shadow-md rounded-md" onClick={() => this.loginWithGoogle()}>
					<img className="inline-block w-6 h-6 align-middle" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"/>
					<span className="pl-4">Login with Google</span>
				</button>
				<button className="max-w-12 leading-4  p-2 shadow-md rounded-md" onClick={() => this.loginWithMicrosoft()}>
					<img className="inline-block w-6 h-6 align-middle" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg"/>
					<span className="pl-4">Login with Microsoft</span>
				</button>
			</div>
		)
	}
}

export default withRouter(Account);