import React, { Component } from 'react'
import {
	withRouter,
	Link
} from 'imports/react-router';

// UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { FirebaseController } from 'components/Firebase';
import './Navigation.scss';

// Logo
import Logo from  'tiktag-simple.svg';

class Navigation extends Component
{
	constructor (pIn)
	{
		super(pIn);
		this.reactToTiktagEvent = new Event('reactToTiktagEvent', {bubbles:true});
		this.sendToTiktag = this.sendToTiktag.bind(this);
		this.onManageRights = this.onManageRights.bind(this);
	}

	sendToTiktag(trigger, data)
	{
		this.reactToTiktagEvent.trigger = trigger;
		this.reactToTiktagEvent.data = data;
		window.dispatchEvent (this.reactToTiktagEvent);
	}

	onBrandClick()
	{
		if (typeof this.props.location.state != 'undefined')
		{
			this.props.history.push({
				pathname: '/ide',
				state: this.props.location.state
			});
		} else
		{
			this.props.history.push({
				pathname: '/ide',
			});
		}
	}

	onLogout(e)
	{
		FirebaseController.logout();
	}

	onManageRights(e)
	{
		console.log ("manage Rights here!");
		this.sendToTiktag("manageRights", null);
	}

	renderLoggedInStatus()
	{
		if (this.props.account)
		{
			return(
				<div className="flex flex-grow justify-self-end self-center text-white pr-2">
					<Dropdown>
      					<Dropdown.Toggle className="navemail" variant="primary" id="dropdown-basic">{this.props.account.email}</Dropdown.Toggle>
      					<Dropdown.Menu>
      						<Dropdown.Item onClick={this.onLogout}>Logout</Dropdown.Item>
      						<Dropdown.Item onClick={this.onManageRights}>manage Rights</Dropdown.Item>
      					</Dropdown.Menu>
      				</Dropdown>
				</div>
			);
		} else 
		{
			return(
				<div className="flex-grow justify-self-end self-center">
					<Link to="/register" className="no-underline hover:no-underline active:no-underline bg-white px-3 mx-1 rounded-lg">Sign up</Link>
					<Link to="/login" className="no-underline hover:text-white active:text-white hover:no-underline active:no-underline bg-primary px-3 mx-1 rounded-lg">Sign in</Link>
				</div>
			);
		}

	}

	render()
	{
		//p-2 no-underline hover:text-white active:text-white hover:no-underline active:no-underline

		return(
			<div className="grid grid-cols-2 sticky h-14 top-0 right-0 left-0 bg-secondary z-50 shadow">
				<div className="flex self-center">
					<img className="self-center"
						 src={Logo} style={{'maxHeight':'20px'}} alt="TikTag Logo">
					</img>
					<div className="text-white" style={{'minHeight':'100%'}}> Development</div>
				</div>
				{ this.renderLoggedInStatus() }
			</div>
		)
	}
}

export default withRouter(Navigation);

/*
					<Link className="p-2 no-underline hover:text-white active:text-white hover:no-underline active:no-underline" to="/account">
						{this.props.account.email}
					</Link>




	renderLoggedInStatus()
	{
		if (this.props.account)
		{
			return(
				<div className="flex flex-grow justify-self-end self-center text-white pr-2">
					<div className="p-2">
						Kontostand
					</div>
					<Link className="p-2 no-underline hover:text-white active:text-white hover:no-underline active:no-underline" to="/collections">
						Collections
					</Link>
					<Link className="p-2 no-underline hover:text-white active:text-white hover:no-underline active:no-underline" to="/notifications">
						<FontAwesomeIcon icon={faBell} />
					</Link>
				 	<Link className="p-2 no-underline hover:text-white active:text-white hover:no-underline active:no-underline" to="/account">
						{this.props.account.email}
					</Link>
				</div>
			);
		} else 

*/