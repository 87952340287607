// Marketplace Page
// Site for Users to add and install ne Tags to your Website

import React, { Component } from 'react'
import {
	withRouter,
	Switch,
	Route,
	Router,
	useRouteMatch
} from 'imports/react-router';

// Components
import { FirebaseController } from 'components/Firebase';

// Logo
import Logo from  'tiktag-01.svg';
// import Logo from  'simpleLogo.svg';

// UI
import { 
	Container,
} from 'react-bootstrap';

// SCSS
import "index.scss";

class Account extends Component
{

	logout()
	{
		FirebaseController.logout();
	}
	
	renderSignInMethod()
	{
		if (this.props.account.providerData[0].providerId == 'password')
		{
			return (
				<div>
					Email & Password
					<div>
						{this.props.account.email}
					</div>
				</div>
			)
		}
	}
	
	render()
	{
		return(
			<Container>
				<button onClick={() => this.logout()}> logout </button>
				<div>
					<div className="sticky top-14 bg-primary px-3 py-2 text-white rounded-md">
						Profil
					</div>
					<div className="grid grid-cols-2 gap-2">
						<div>Sign-in Method</div>
						<div>{this.renderSignInMethod()}</div>
					</div>
				</div>
				<div>
					<div className="sticky top-14 bg-primary px-3 py-2 text-white rounded-md">
						Rechnungen
					</div>
				</div>
			</Container>
		)
	}
}

export default withRouter(Account);