// Marketplace Page
// Site for Users to add and install ne Tags to your Website

import React, { Component } from 'react'
import {
	withRouter,
	Switch,
	Route,
	Router,
	useRouteMatch
} from 'imports/react-router';

// Components
import { FirebaseController } from 'components/Firebase';

// Logo
import Logo from  'tiktag-01.svg';
// import Logo from  'simpleLogo.svg';

// UI
import { 
	Container,
} from 'react-bootstrap';

// SCSS
import "index.scss";

class Notifications extends Component
{

	logout()
	{
		FirebaseController.logout();
	}

	renderNotifications()
	{
		return;
	}

	render()
	{
		return(
			<Container>
				<h1>Notifications</h1>
				<div className="grid grid-cols-3 gap-4 bg-primary text-white p-2 rounded-md">
					<div>
						Type
					</div>
					<div>
						Betreff
					</div>
					<div>
						Datum
					</div>
				</div>
				<div className="grid grid-cols-3 gap-4 p-2">
					{this.renderNotifications()}
				</div>
			</Container>
		)
	}
}

export default withRouter(Notifications);