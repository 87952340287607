import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import TikTag from 'TikTag';



ReactDOM.render(
	<TikTag />,
	document.getElementById('root')
);