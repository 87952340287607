import * as firebase from 'firebase/app';
import 'firebase/auth';


export const FirebaseController = new function() 
{

	this.init = (props) => new Promise((resolve, reject) => 
	{
		// props: onAuthChanged(), apiKey, authDomain
		
		const firebaseConfig = {
			apiKey: props.apiKey,
			authDomain: props.authDomain,
		}

		firebase.initializeApp(firebaseConfig);

		firebase.auth().onAuthStateChanged((user) => 
		{
			props.onAuthChanged(user);
		}, (err) => {
			console.warn(err);
			reject();
		})
		
		resolve();
	})

	this.createUser = async (email, password) => 
	{
		await firebase.auth().createUserWithEmailAndPassword(email, password)
	}

	this.loginWithEmailAndPassword = async (email, password) => 
	{
		await firebase.auth().signInWithEmailAndPassword(email, password);
	}

	this.loginWithGoogle = () => 
	{
		const provider = new firebase.auth.GoogleAuthProvider();

		firebase.auth().signInWithPopup(provider).then((result) => {
			var credential = result.credential;
			var token = credential.accessToken;
			var user = result.user;

//			console.log(result);
		}).catch(err => 
		{
			console.warn(err);
		});
	}

	this.loginWithMicrosoft = () =>
	{
		const provider = new firebase.auth.OAuthProvider('microsoft.com');

		firebase.auth().signInWithPopup(provider)
			.then((result) => {

				var credential = result.credential;
				var token = credential.accessToken;
				var user = result.user;

				console.log(result);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	this.logout = async () => 
	{
//		console.log('logout');
		firebase.auth().signOut().then(() => 
		{
			window.location.reload();
		}).catch(err => 
		{
			console.warn(err);
		});
	}

	this.getIDToken = (callback) =>
	{
		let result = {};
		firebase.auth().currentUser.getIdToken(true).then((idToken) => 
		{
			callback(idToken);
		}).catch((error) => 
		{
			console.warn(error);
		});

	}

	this.getIDToken = async () =>
	{
		var idtoken;
		idtoken = await firebase.auth().currentUser.getIdToken(true);
		return idtoken;
	}

	this.getCurrentUser = () =>
	{
		return (firebase.auth().currentUser);
	}
	/*
	// template
	this.AuthView = _view(() => {
		if (this.store.authState === 'logged') {
			return <div>
				auth: {this.store.email} <button onClick={Auth.logout}>logout</button>
			</div>
		} else if (this.store.authState === 'not logged') {
			return <div>
				auth: <button onClick={Auth.loginWithGoogle}>login with google</button>
			</div>
		}

		return <div>
			auth: pending
		</div>
	})
	*/

}()