// Register Component
// Handles registration

import React, { Component } from 'react';
import { Link } from 'imports/react-router';

// Components
import { FirebaseController } from 'components/Firebase';
import ProviderLogin from 'components/ProviderLogin';

// UI
import { 
	Container,
	Row,
	Col,
	Navbar,
	Nav,
	Brand,
	Card,
	CardColumns,
	Button,
	Form,
	FormControl,
	Image,
} from 'react-bootstrap';

// tools
import {
	isEmail
} from 'tools/tools';

import './Register.scss';

class Register extends Component
{
	constructor()
	{
		super();

		this.state = {
			inputs: {
				firstName: {
					placeholder: "Enter first name",
					props: {
						value: "",
						error: false,
						helperText: "",
						inputRef: React.createRef(),
					},
				},
				lastName: {
					placeholder: "Enter last name",
					props: {
						value: "",
						error: false,
						helperText: "",
						inputRef: React.createRef(),
					},
				},
				email: {
					placeholder: "Enter email",
					props: {
						value: "",
						error: false,
						helperText: "",
						inputRef: React.createRef(),
					},
				},
				password: {
					inputprops: true,
					showPassword: false,
					placeholder: "Enter password",
					props: {
						value: "",
						error: false,
						helperText: "",
						type: "password",
						inputRef: React.createRef(),
					},
				}, 
				repeatPassword: {
					inputprops: true,
					showPassword: false,
					placeholder: "repeat password",
					props: {
						value: "",
						error: false,
						helperText: "",
						type: "password",
						inputRef: React.createRef(),
					},
				},
			},	
			firebaseError: false
		}
	}

	async componentDidMount()
	{
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		console.log(params.uuid);
		if (params.uuid)
			localStorage.setItem('uuid', params.uuid);
		if (params.email)
		{
			console.log(params.email);
			const inputs = {...this.state.inputs};
			inputs.email.props.value = params.email;
			this.setState({inputs});
		}
	}

	preventDefaultEvent(e)
	{
		e.preventDefault();
	}

	changePasswordVisibility(input)
	{
		let inputs = {...this.state.inputs};

		if (inputs[input].props.type !== "password")
			inputs[input].props.type = "password";
		else
			inputs[input].props.type = "text";

		inputs[input].showPassword = !inputs[input].showPassword

		this.setState({inputs});
	}

	changeTextInput(e)
	{
		const id = e.target.id;
		const value = e.target.value;
		const inputs = {...this.state.inputs};

		inputs[id].props.value = value
		this.setState({inputs});
	}

	validateInputs()
	{
		let inputs = {...this.state.inputs};
		var err = false

		for (const key in inputs)
		{
			inputs[key].props.error = false;
			inputs[key].props.helperText = "";
			if (inputs[key].props.value === "")
			{
				if (!err)
					inputs[key].props.inputRef.current.focus();
				inputs[key].props.error = true;
				inputs[key].props.helperText = `a ${key} is reqired`;
				err = true;
			}
			if (key === "email")
			{
				if (!isEmail(inputs[key].props.value))
				{
					err = true;
					inputs[key].props.error = true;
					inputs[key].props.helperText = `${key} is invalid`;
				}
			}
			if (key === "repeatPassword")
			{
				if (this.state.inputs[key].props.value !== this.state.inputs.password.props.value)
				{
					err = true;
					inputs[key].props.error = true;
					inputs[key].props.helperText = `${key} does not match`;
				}
			}
		}
		this.setState({inputs});
		return !err;
	}

	async registerAccount()
	{
		const validation = this.validateInputs();
		if (validation)
		{
			try
			{
				await FirebaseController.createUser(this.state.inputs.email.props.value, this.state.inputs.password.props.value);
			} catch (e)
			{
				this.setState({firebaseError: true, firebaseMessage: e.message});
			}
		}
	}

	renderInputs()
	{
		var inputs = [];
		for (const key in this.state.inputs)
		{
			let InputProps = {};
			const label = key[0].toUpperCase() + key.substring(1);
			inputs.push(
				<div className={`signup-${key} signup-input-wrapper`} key={key}>
					<div className={`signup-${key} signup-label`}>{label}</div>
					<input className="signup-input" id={key} type={key} value={this.state.inputs[key].props.value} ref={this.state.inputs[key].props.inputRef} placeholder={this.state.inputs[key].placeholder} onChange={e => this.changeTextInput(e)}/>
					<div key={key} className={`signup-${key} signup-error`}>{this.state.inputs[key].props.helperText}</div>
				</div>
			)
		}
		return inputs;
	}

	renderFirebaseError()
	{
		if (this.state.firebaseError)
		{
			return (
				<div className="login-firebase-error">{this.state.firebaseMessage}</div>
			);
		}
		return;
	}

	render()
	{
		return(
			<div className="signup-wrapper">
				<div className="headline">Sign Up</div>
				<div>
					{this.renderInputs()}
				</div>
				{this.renderFirebaseError()}
				<button className="signup-button" onClick={() => this.registerAccount()} >Register &amp; Login</button>
				<div className="signup-spacer"></div>
				<ProviderLogin/>
			</div>
		)
	}}

export default Register;