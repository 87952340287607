// Homepage of TikTag 
// doesnt do much. it just gives some info and has Links to Register and Stuff (Pricing, Login, ...)
/*
						<Typography variant="h4" component="h1" gutterBottom color="textPrimary">
							<div>ABCD</div>
							<img src="tiktag-01.svg"></img>
						</Typography>

*/
import React, { Component } from 'react';
import { withRouter, Link } from 'imports/react-router';
import { Redirect } from 'react-router-dom';

// css
import './Home.scss';

// UI
import { 
	Container
} from 'react-bootstrap';

class Home extends Component
{
	constructor(props)
	{
		super(props);

//		console.log ("Home Construct");
//		this.props.history.replace('/home');
	}


	componentDidMount()
	{
//		console.log ("mount Home!");
//		console.dir (this.props);
	}

	render()
	{
		if (this.props.account !== null)
			return(<Redirect to="/ide"/>);
		else
			return(<Redirect to="/login"/>);
//		return(
/*			<div className="bg-primary-dark p-4 text-gray-300">
				<Container className="grid justify-items-center">
					<img style={{width: '12%'}} src="tiktag-01.svg" alt="Logo"></img>
					<div className="text-6xl">
						JavaScript rocks!
					</div>
					<div className="text-4xl">
						I promise
					</div>
					<div className="py-4">
						<Link className="mx-2 p-2 inline-block text-center w-36 no-underline hover:text-white active:text-white hover:no-underline active:no-underline bg-gray-300 rounded text-secondary" to="/marketplace">
							See Marketplace
						</Link>
						<Link className="mx-2 p-2 inline-block text-center w-36 no-underline hover:text-white active:text-white hover:no-underline active:no-underline bg-gray-300 rounded text-secondary" to="/ide">
							Try IDE
						</Link>
					</div>
				</Container>
			</div>*/
//			<link to="/login"></link>
//		)
	}
}

export default withRouter(Home);
